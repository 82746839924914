.last {
    margin-bottom: 30px;
}

.quicklinks {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__top,
    &__bottom {
        padding-left: 0;
        padding-right: 0;
    }

    &__bottom figure {
        margin-top: 2em;
    }

    &__figure {
        width: 100%;
        margin: auto;
        position: relative;
        transition: transform 0.3s ease;
    }

    &__figure img {
        max-width: 100%;
        height: auto;
        border-radius: 30px;
    }

    &__figcaption {
        text-align: center;
        font-size: 18px;
        position: absolute;
        bottom: 0;
        width: 100%;
        color: getColor('blue');
        background-color: getColor('background_figcaption');
        padding: 10px;
        margin: 0;
        border-radius: 0 0 30px 30px;
    }

    &__figure:hover {
        transform: scale(1.025);
    }
}

.second {
    margin-top: 2em;
}

@media screen and (min-width: 768px) {
    .last {
        margin-bottom: 50px;
    }

    .quicklinks {
        min-height: calc(100vh - 300px);
        &__figure {
            text-align: center;
        }

        &__figure img {
            max-width: 100%;
            height: auto;
        }

        &__figcaption {
            font-size: 24px;
        }
    }
}

@media screen and (min-width: 1200px) {
    .last {
        margin-bottom: 80px;
    }

    .quicklinks {
        &__top,
        &__bottom {
            display: flex;
            gap: 0;
        }

        &__right {
            margin-right: calc((100% - (3 * 300px)) / 6);
            margin-left: calc((100% - (3 * 300px)) / 12);
        }

        &__left {
            margin-right: calc((100% - (3 * 300px)) / 12);
            margin-left: calc((100% - (3 * 300px)) / 6);
        }
    }

    .second {
        margin-top: 0;
    }
}
