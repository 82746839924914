.main {
    margin-top: 30px;

    &__header {
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 2;
        font-size: 20px;
        margin-bottom: 1rem;
        text-transform: uppercase;
        color: #4a8795;
    }

    &__text {
        font-size: 16px;
        display: block;
        justify-content: center;
        align-items: center;
        line-height: 2;
        padding-left: 0;
        padding-right: 0;
    }
}

.content {
    margin-top: 0;
    margin-bottom: 30px;
    width: 100%;
}

@media screen and (min-width: 768px) {
    .main {
        margin-top: 50px;

        &__header {
            margin-bottom: 1rem;
            font-size: 22px;
        }
    }

    .content {
        margin-top: 60px;
        margin-bottom: 60px;
    }

    .decor--bottom {
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }
}

@media screen and (min-width: 1200px) {
    .main {
        &__header {
            font-size: 30px;
        }

        &__text {
            font-size: 20px;
        }
    }

    .content {
        margin-top: 70px;
        margin-bottom: 70px;
    }
}
