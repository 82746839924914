@import 'utils/variables';

body {
    font-family: 'Merriweather', sans-serif;
    color: getColor('primary');
    margin-left: auto;
    margin-right: auto;
    position: relative;
    letter-spacing: 0.1rem;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

body:has(.is-open) {
    overflow: hidden;
}

*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.home {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 25px;
    padding-right: 25px;
}

ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

a {
    color: inherit;
    text-decoration: none;
}

p a:hover {
    text-decoration: underline;
}

@media screen and (min-width: 768px) {
    body {
        max-width: 1199px;
    }

    .container {
        max-width: 1199px;
        padding-left: 50px;
        padding-right: 50px;
    }

    body:has(.is-open) {
        overflow: auto;
    }
}

@media screen and (min-width: 1200px) {
    body {
        max-width: 2000px;
        margin-left: auto;
        margin-right: auto;
        box-shadow: $main_shadow;
    }

    .home {
        min-height: calc(100vh - 115px - 295px);
    }

    .container {
        max-width: 1800px;
        padding-left: 100px;
        padding-right: 100px;
    }
}

@import 'components/header.scss';
@import 'components/slider.scss';
@import 'components/content.scss';
@import 'components/ql.scss';
@import 'components/footer.scss';
@import 'components/modal.scss';
@import 'components/cennik.scss';
