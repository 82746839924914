.menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    padding: 70px 50px;
    background-color: getColor('white');
    z-index: 100;
    transform: translateX(100%);
    list-style: none;

    &__pages {
        list-style: none;
    }

    &__pages-link {
        color: getColor('primary');
        display: block;
        text-decoration: none;
        font-weight: 500;
        font-size: 24px;
        line-height: 1.175;
        letter-spacing: 0.02em;
        padding-bottom: 20px;

        &:hover,
        &:focus,
        &:active {
            color: getColor('secondary');
        }
    }

    &__toggle {
        background-color: transparent;
        cursor: pointer;
        border: none;
        position: absolute;
        top: 10px;
        right: 15px;
        color: getColor('primary');

        &:hover,
        &:active {
            color: getColor('secondary');
            background-color: transparent;
        }
    }
}

.menu.is-open {
    transform: translateX(0);
    height: 105vh;
}

@media screen and (min-width: 768px) {
    .menu.is-open {
        height: auto;
        width: auto;
        padding: 20px;
        overflow: hidden auto;
        left: auto;
        right: 25px;
        top: 70px;
        border-radius: 5%;
        position: absolute;
        padding: 30px;
    }

    .menu {
        &__toggle {
            display: none;
        }

        &__pages-link {
            font-weight: 500;
            font-size: 18px;
        }
    }
}

@media screen and (min-width: 1200px) {
    .menu.is-open {
        right: 6%;
        top: 90px;
        padding: 50px;
    }

    .menu {
        &__pages-link {
            font-size: 20px;
        }
    }
}

@media screen and (min-width: 1800px) {
    .menu.is-open {
        right: calc((100% - 1800px) / 2 + 80px);
    }
}
