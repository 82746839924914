.section {
    &__header {
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 2;
        font-size: 18px;
        margin-bottom: 1rem;
        text-transform: uppercase;
        color: getColor('blue');
    }

    &__item {
        margin-bottom: 2rem;
    }

    &__item-header {
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 2;
        font-size: 16px;
        margin-bottom: 1rem;
        text-transform: uppercase;
        color: getColor('blue');
    }

    &__item-text {
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 2;
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 3rem;
    }
}

@media screen and (min-width: 768px) {
    .section {
        &__header {
            margin-bottom: 1rem;
            font-size: 22px;
        }

        &__item-header {
            margin-bottom: 1rem;
            font-size: 18px;
        }
    }
}

@media screen and (min-width: 1200px) {
    .section {
        &__header {
            font-size: 28px;
        }

        &__item-header {
            font-size: 26px;
        }

        &__item-text {
            font-size: 26px;
        }
    }
}
